<template>
  <div>
    <a-card>
      <a-descriptions title="订单信息" :column="2">
        <a-descriptions-item label="订单号">
          {{ info.orderSn }}
        </a-descriptions-item>
        <a-descriptions-item label="B端渠道单号">
          {{ info.pingaOrderSn }}
        </a-descriptions-item>
        <a-descriptions-item label="支付方式">
          {{ info.payModel|payModelFormat }}
        </a-descriptions-item>
        <a-descriptions-item label="平安订单状态">
          {{ orderdata.statusName }}
        </a-descriptions-item>

        <a-descriptions-item label="重要客户标识">
          {{ info.vipCustomer|vipCustomerFormat }}
        </a-descriptions-item>
        <a-descriptions-item label="银行经营业务属主">
          {{ info.businessOwner }}
        </a-descriptions-item>

        <a-descriptions-item label="家庭账户">
          {{ info.isEdit==1?"是":"否" }}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card>
      <a-descriptions title="申请信息" :column="2">
        <a-descriptions-item label="产品类型">
          {{ info.orderType|orderTypeFormat }}
        </a-descriptions-item>
        <a-descriptions-item label="订单类型">
          {{ info.type|typeFormat }}
        </a-descriptions-item>
        <a-descriptions-item label="B端渠道">
          {{ info.channelName }}
        </a-descriptions-item>
        <a-descriptions-item label="B端服务产品">
          {{ info.channelProduct }}
        </a-descriptions-item>
        <a-descriptions-item label="内部一级产品">
          {{ info.productName }}
        </a-descriptions-item>
        <a-descriptions-item label="内部二级产品">
          {{ info.productChildName }}
        </a-descriptions-item>
        <a-descriptions-item label="姓名">
          {{ info.truename }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ info.idcard }}
        </a-descriptions-item>
        <a-descriptions-item label="手机号码">
          {{ info.mobile }}
        </a-descriptions-item>
        <a-descriptions-item label="年龄">
          {{ info.userAge }}
        </a-descriptions-item>
        <a-descriptions-item label="性别">
          {{ info.sex==1?"男":"" }}    {{ info.sex==2?"女":"" }}
        </a-descriptions-item>
        <a-descriptions-item label="客户信息备注">
          {{ info.remarks }}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card>
      <a-descriptions title="录入信息" :column="2">
        <a-descriptions-item label="套餐">
          {{ info.mealName }}
        </a-descriptions-item>
        <a-descriptions-item label="使用地区">
          {{ info.regionName }}
        </a-descriptions-item>
        <a-descriptions-item label="意向医院">
          {{ info.nosoName }}
        </a-descriptions-item>
        <a-descriptions-item label="意向科室">
          {{ info.depName }}
        </a-descriptions-item>
        <a-descriptions-item label="对应内部科室">
          {{ info.sectionName }}- {{ info.sectionSecName }}
        </a-descriptions-item>

        <a-descriptions-item label="预约医生">
          {{ info.doctorName }}
        </a-descriptions-item>

        <a-descriptions-item label="医生职称">
          {{ info.doctorPosition }}
        </a-descriptions-item>
        <a-descriptions-item label="医院地址">
          {{ info.address }}
        </a-descriptions-item>

        <a-descriptions-item label="预约时间">
          {{ info.subscribeDate|formatterDateTime }}
        </a-descriptions-item>

        <a-descriptions-item label="陪诊人员">
          {{ info.escortName }}
        </a-descriptions-item>

        <a-descriptions-item label="状态">
          <span v-if="info.status ===1">待跟进</span>
          <span v-if="info.status ===2">待预约</span>
          <span v-if="info.status ===3">已预约</span>
          <span v-if="info.status ===4">服务完成(完成)</span>
          <span v-if="info.status ===5">服务取消(完成)</span>
          <span v-if="info.status ===6">服务爽约(完成)</span>
          <span v-if="info.status ===7">已取消</span>
          <span v-if="info.status ===8">待支付</span>
        </a-descriptions-item>
        <a-descriptions-item label="注意事项">
          {{ info.descinfo }}
        </a-descriptions-item>

        <a-descriptions-item label="现场服务小结">
          {{ info.finishRemarks }}
        </a-descriptions-item>

      </a-descriptions>
    </a-card>
  </div>

</template>
<script>
import { formatterDate, formatterDateTime } from '@/utils/util'
import { getById } from '@/api/order'

export default {
  filters: {
    dataFormat (msg) {
      return formatterDate(msg)
    },
    payModelFormat (status) {
      if (status === 2) {
        return '权益支付'
      } else {
        return '现金支付'
      }
    },
    vipCustomerFormat (status) {
      if (status === 2) {
        return '其他客户'
      } else {
        return '重要客户'
      }
    },
    orderTypeFormat (status) {
      if (status === 1) {
        return '体检'
      }
      if (status === 2) {
        return '门诊'
      }
      if (status === 3) {
        return '住院'
      }
      if (status === 4) {
        return '咨询'
      }
      if (status === 5) {
        return '其他'
      }
    },
    typeFormat (status) {
      if (status === 2) {
        return '渠道订单'
      } else {
        return '自建订单'
      }
    },

    formatterDateTime (msg) {
      return formatterDateTime(msg)
    }
  },
  data () {
    return {
      orderId: 0,
      info: {
        truename: undefined
      },
      orderdata: {
        statusName: undefined
      }
    }
  },
  methods: {
    setOrderId (orderId) {
      this.orderId = orderId
      getById({ orderId: orderId })
        .then(res => {
          console.log(res)
          this.info = res.result.info
          if (res.result.json.data !== undefined) {
            this.orderdata = res.result.json.data
          }
        })
    }
  }
}
</script>
